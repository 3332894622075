var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fleet"},[_c('div',{staticClass:"absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12"}),_c('div',{staticClass:"px-4 md:px-10 mx-auto w-full relative -mt-11"},[_c('HelpBar',{on:{"getData":_vm.getData}}),_c('div',{staticClass:"flex flex-wrap mt-4"},[_c('SlideUp',[_c('ListTable',{attrs:{"tableName":_vm.$t('damages'),"headers":_vm.headers,"tableData":_vm.$store.state.damages.damages,"loading":_vm.$store.state.damages.loading,"searchable":false},scopedSlots:_vm._u([{key:"top-right-corner",fn:function(){return [_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mx-2 rounded px-2 py-1 cursor-pointer",class:{
                  'bg-gray-700 text-gray-50': _vm.view == 'unresolved',
                  'border border-gray-700': _vm.view != 'unresolved'
                },on:{"click":function($event){return _vm.setView('unresolved')}}},[_vm._v(" "+_vm._s(_vm.$t("unresolved"))+" ")]),_c('div',{staticClass:"mx-2 rounded px-2 py-1 cursor-pointer",class:{
                  'bg-gray-700 text-gray-50': _vm.view == 'resolved',
                  'border border-gray-700': _vm.view != 'resolved'
                },on:{"click":function($event){return _vm.setView('resolved')}}},[_vm._v(" "+_vm._s(_vm.$t("resolved"))+" ")]),_c('div',{staticClass:"mx-2 rounded px-2 py-1 cursor-pointer",class:{
                  'bg-gray-700 text-gray-50': _vm.view == 'all',
                  'border border-gray-700': _vm.view != 'all'
                },on:{"click":function($event){return _vm.setView('all')}}},[_vm._v(" "+_vm._s(_vm.$t("all"))+" ")])])]},proxy:true}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }