<template>
  <div class="fleet">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full relative -mt-11">
      <HelpBar @getData="getData">
        <!-- <template v-slot:right>
          <div
            @click="handleViewAll"
            class="flex mx-3 px-2 py-1 rounded"
            :class="{
              'bg-gray-700  text-gray-50': viewAll,
              'g-gray-50 text-gray-700 border': !viewAll
            }"
          >
            {{ $t("show all date") }}
          </div>
        </template> -->
      </HelpBar>

      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('damages')"
            :headers="headers"
            :tableData="$store.state.damages.damages"
            :loading="$store.state.damages.loading"
            :searchable="false"
          >
            <template v-slot:top-right-corner>
              <div class="flex">
                <div
                  class="mx-2 rounded px-2 py-1 cursor-pointer"
                  :class="{
                    'bg-gray-700 text-gray-50': view == 'unresolved',
                    'border border-gray-700': view != 'unresolved'
                  }"
                  @click="setView('unresolved')"
                >
                  {{ $t("unresolved") }}
                </div>
                <div
                  class="mx-2 rounded px-2 py-1 cursor-pointer"
                  :class="{
                    'bg-gray-700 text-gray-50': view == 'resolved',
                    'border border-gray-700': view != 'resolved'
                  }"
                  @click="setView('resolved')"
                >
                  {{ $t("resolved") }}
                </div>
                <div
                  class="mx-2 rounded px-2 py-1 cursor-pointer"
                  :class="{
                    'bg-gray-700 text-gray-50': view == 'all',
                    'border border-gray-700': view != 'all'
                  }"
                  @click="setView('all')"
                >
                  {{ $t("all") }}
                </div>
              </div>
            </template>
          </ListTable>
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";
import HelpBar from "../components/HelpBar.vue";
export default {
  name: "Damages",
  components: {
    HelpBar,
    ListTable,
    SlideUp
  },
  data() {
    return {
      view: "unresolved",
      // viewAll: false,
      headers: [
        {
          name: "asset",
          key: "bike.name",
          type: "link",
          linkInfo: { name: "Fleet-Detail", routeKey: "id", varKey: "bike_id" }
        },
        { name: "labels", key: "tags", type: "labels" },
        {
          name: "resolved",
          key: "read",
          type: "tag",
          true: "green",
          false: "red"
        },
        {
          name: "user",
          key: "user.name",
          type: ""
        },
        {
          name: "type",
          key: "type.name"
        },
        {
          name: "ticket(s) referenced",
          type: "tickets",
          key: "tickets"
          // key: "subject"
        },
        {
          name: "notes",
          key: "notes",
          type: ""
        },
        { name: "reported", key: "inserted_at", type: "dateTime" }
      ]
    };
  },
  methods: {
    setView(view) {
      this.view = view;
      this.getData();
    },
    // handleViewAll() {
    // this.viewAll = !this.viewAll;
    //   this.$store.commit("SET_DATE_RANGE", []);
    //   this.getData();
    // },
    getData() {
      let options = { resolved: false };
      if (this.view == "all") {
        options.resolved = "all";
      } else if (this.view == "resolved") {
        options.resolved = true;
      }
      this.$store.dispatch("damages/getDamages", options);
    }
  },
  created() {
    this.$store.commit("SET_DATE_RANGE", []);
    this.$store.dispatch("hubs/getAllHubs", hubs => {
      this.$store.commit(
        "SET_HUBS",
        hubs.map(({ id }) => id)
      );
      this.getData();
    });
  }
};
</script>
